import LocomotiveScroll from "locomotive-scroll";
import { initStickyScrollSequence } from "./scroll/sticky-scroll";
import { gsap } from "gsap";

// might want to import this in a different way
import "locomotive-scroll/src/locomotive-scroll.scss";

// export let scroll;

export const getScroll = () => {
	if ((!scroll || scroll.length === 0) && document.querySelector("[data-scroll-container]")) {
		scroll = new LocomotiveScroll({
			el: document.querySelector("[data-scroll-container]"),
			smooth: true,
			// smartphone: { smooth: true },
			// tablet: { smooth: true },
		});
		window.scroll = scroll;
	}
	return scroll;
};

export const clearAllInstancesOfScroll = () => {
	if (scroll && scroll.destroy) {
		scroll.destroy();
		scroll = null;
		window.scroll = null;
	}
};

export const restartScroll = () => {
	initScrollFunctions();
};

// scroll polling every 500ms
let pollingInterval = null;
let lastContentHeight = document.body.scrollHeight;

const scrollPolling = (duration = 500) => {
	const scroll = getScroll();
	if (!scroll) return;

	const currentContentHeight = document.body.scrollHeight;
	if (currentContentHeight !== lastContentHeight) {
		scroll.update();
		lastContentHeight = currentContentHeight;
	}

	if (pollingInterval) {
		clearTimeout(pollingInterval);
	}
	pollingInterval = setTimeout(() => scrollPolling(duration), duration);
};

export const initScrollFunctions = () => {
	clearAllInstancesOfScroll();
	const scroll = getScroll();
	if (!scroll) return;
	const targetSection = document.documentElement.dataset.targetSection;
	if (document.querySelector(".has-scroll-smooth")) {
		// initial update once we detect the scroll container for first page content paint
		scroll.update();

		// setup scroll polling
		scrollPolling();

		// locomotive scroll detection  (for desktop)
		if (!scroll.isScrolling) {
			if (targetSection) {
				scrollToSection(targetSection);
			}
		}

		handleDefaultHashAnchors(targetSection);

		// initScrollSequences();

		scroll.on("call", (func, direction, obj) => {
			checkAnimNameAndTrigger(func, direction, obj);
		});

		// This is my generic scroll event that will take care of everyday little things
		// See the initScrollSequences function for specialized scroll events
		scroll.on("scroll", (instance) => {
			// This will make sure the position fixed elements are properly aligned
			document.querySelectorAll(".fixed").forEach((fixedEl) => {
				fixedEl.style.transform = `translate3d(0, ${instance.scroll.y}px, 0)`;
			});
		});

		// also make sure that any scroll element with a
		// negative value in data-scroll-speed, add a class
		// to overcorrect on taller screens
		document.querySelectorAll("[data-scroll-speed]").forEach((element) => {
			if (parseFloat(element.getAttribute("data-scroll-speed")) < 0) {
				element.classList.add("negative-scroll-speed");
			}
		});
	} else {
		// scroll to the target using vanilla js
		if (targetSection) {
			targetSection && document.querySelector(targetSection).scrollIntoView({ behavior: "smooth" });
			window.history.pushState({}, "", targetSection);
		}

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				const { top } = entry.boundingClientRect;
				const prevTop = entry.target.dataset.prevTop || top;

				// Update the prevTop with the current top position
				entry.target.dataset.prevTop = top;

				// Check if the scroll is above the event target
				if (entry.isIntersecting || top < prevTop) {
					entry.target.classList.add("is-inview");
					const func = entry.target.dataset.scrollCall;
					const obj = {
						el: entry.target,
					};
					checkAnimNameAndTrigger(func, null, obj);
				}
			});
		});
		// Function to handle initial state check
		const checkInitialState = (element) => {
			const { top } = element.getBoundingClientRect();
			const isInView = top >= 0 && top <= window.innerHeight;
			if (isInView) {
				element.classList.add("is-inview");
				const func = element.dataset.scrollCall;
				const obj = {
					el: element,
				};
				checkAnimNameAndTrigger(func, null, obj);
			}
		};

		// Create an observer for every instance of [data-scroll] on the current page
		const scrollElements = document.querySelectorAll("[data-scroll]");
		scrollElements.forEach((el) => {
			checkInitialState(el);
			observer.observe(el);
		});
	}
	removeOnThinScreens();
	// setTimeout(() => {
	// 	scroll.update();
	// }, 2000);
};

const checkAnimNameAndTrigger = (func, direct, obj) => {
	switch (func) {
		case "fade-in":
			fadeIn(obj);
			break;
		case "fade-up":
			fadeUp(obj);
			break;
		case "fade-left":
			fadeLeft(obj);
			break;
		case "fade-right":
			fadeRight(obj);
			break;
		case "fade-down":
			fadeDown(obj);
			break;
		case "flip-up":
			flipUp(obj);
			break;
		case "flip-right":
			flipRight(obj);
			break;
		case "flip-left":
			flipLeft(obj);
			break;
		case "flip-down":
			flipDown(obj);
			break;
		case "overlay-previous":
			overlayPrevious(obj);
			break;
	}
};

export const fadeIn = (obj) => {
	const delay = obj.el.dataset.scrollDelay || 0;
	obj.el.style.transitionDelay = `${delay}ms`;
	obj.el.classList.add("fade-in");
};

export const fadeUp = (obj) => {
	const delay = obj.el.dataset.scrollDelay || 0;
	obj.el.style.transitionDelay = `${delay}ms`;
	obj.el.classList.add("fade-up");
};

export const fadeLeft = (obj) => {
	const delay = obj.el.dataset.scrollDelay || 0;
	obj.el.style.transitionDelay = `${delay}ms`;
	obj.el.classList.add("fade-left");
};

export const fadeRight = (obj) => {
	const delay = obj.el.dataset.scrollDelay || 0;
	obj.el.style.transitionDelay = `${delay}ms`;
	obj.el.classList.add("fade-right");
};

export const fadeDown = (obj) => {
	const delay = obj.el.dataset.scrollDelay || 0;
	obj.el.style.transitionDelay = `${delay}ms`;
	obj.el.classList.add("fade-down");
};

const overlayPrevious = (obj) => {
	const { el } = obj; // Current section
	const previous = el.previousElementSibling; // Previous section

	if (previous) {
		const stickyElement = previous.querySelector("[data-scroll-sticky]");
		const counterTranslateYElement = stickyElement.querySelector(".counter-translate-y-on-overlap"); // The new wrapper
		const previousHeight = previous.getBoundingClientRect().height;
		const scrollSpeedFactor = 3; // Adjust this factor to control translation speed

		let previousTranslationY = 0; // Track the Y translation for the previous section
		let hasStickyElementStopped = false; // Track if the sticky element has stopped moving

		// Helper function to get current translateY from the transform matrix
		const getTranslateYFromMatrix = (element) => {
			const style = window.getComputedStyle(element);
			const transform = style.transform || style.webkitTransform || style.mozTransform;

			if (transform && transform !== "none") {
				const matrix = transform.includes("matrix3d") ? transform.split(",") : transform.split(",");
				// Extract translateY based on whether it's matrix3d or matrix
				const translateY = matrix.length === 16 ? parseFloat(matrix[13]) : parseFloat(matrix[5]);
				return translateY;
			}

			return 0; // No transform applied
		};

		// Move `lastStickyTranslateY` to the higher scope so it persists between scroll events
		let lastStickyTranslateY = getTranslateYFromMatrix(stickyElement);

		scroll.on("scroll", (instance) => {
			const currentSectionTop = el.getBoundingClientRect().top;
			const previousSectionBottom = previous.getBoundingClientRect().bottom;
			const viewportHeight = window.innerHeight;

			// Get current translateY of the sticky element from its transform matrix
			const stickyTranslateY = getTranslateYFromMatrix(stickyElement);

			// Check if sticky element is still moving (has not hit the end of its sticky range)
			const stickyElementIsMoving = stickyTranslateY !== lastStickyTranslateY;

			// Calculate progress only if the previous section is fully scrolled out
			if (previousSectionBottom <= viewportHeight && currentSectionTop > -previousHeight) {
				const progress = Math.min(1, (viewportHeight - currentSectionTop) / viewportHeight);

				// Calculate the translation for the previous section
				const translateYValue = (previousHeight * progress) / scrollSpeedFactor;

				// Apply translation to the previous section
				previousTranslationY = translateYValue;
				previous.style.transform = `translate3d(0, ${previousTranslationY}px, 0)`;

				// Apply counter-translation to the inner wrapper element only if sticky element is moving
				if (stickyElementIsMoving && !hasStickyElementStopped) {
					// Sticky element is still moving, apply counter-translation
					const stickyTranslationY = -previousTranslationY;
					counterTranslateYElement.style.transform = `translate3d(0, ${stickyTranslationY}px, 0)`;

					// Update last translateY value
					lastStickyTranslateY = stickyTranslateY;
				} else if (!stickyElementIsMoving && !hasStickyElementStopped) {
					// Sticky element has stopped moving for the first time, stop counter-translation
					hasStickyElementStopped = true;
				}
			} else {
				// Reset translation if outside the range or scrolling back up
				previous.style.transform = `translate3d(0, 0, 0)`;

				// Reset counter-translate wrapper when moving up
				if (counterTranslateYElement) {
					counterTranslateYElement.style.transform = `translate3d(0, 0, 0)`;
				}

				// Reset sticky movement tracking when scrolling back up
				if (stickyElement && stickyTranslateY < lastStickyTranslateY) {
					// Reset both values when scrolling back up
					hasStickyElementStopped = false;
					lastStickyTranslateY = getTranslateYFromMatrix(stickyElement); // Update on scroll up to track changes again
					previous.style.transform = `translate3d(0, 0, 0)`;
					counterTranslateYElement.style.transform = `translate3d(0, 0, 0)`;
				}
			}
		});
	}
};

// locomotive
const removeOnThinScreens = () => {
	let isMobile = false;
	window.addEventListener("DOMContentLoaded", () => {
		if (window.innerWidth < 1024) {
			isMobile = true;
		}
	});
	window.addEventListener("resize", () => {
		if (window.innerWidth < 1024 && !isMobile) {
			window.location.reload();
		} else if (window.innerWidth >= 1024 && isMobile) {
			window.location.reload();
		}
	});
};

const scrollToSection = (targetSection) => {
	setTimeout(() => {
		scroll.scrollTo(document.querySelector(targetSection), {});
		scroll.update();
		window.history.pushState({}, "", targetSection);
	}, 50);
};

const handleDefaultHashAnchors = (targetSection) => {
	// get all anchor tags with an href that starts with # but doesn't end with # because I don't want to include the ones that are just #
	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"])');

	anchors.forEach((anchor) => {
		anchor.addEventListener("click", function (e) {
			e.preventDefault();
			const target = this.getAttribute("href");
			scrollToSection(target);
		});
	});
};

const initScrollSequences = () => {
	// check if there is any element that exists called data-scroll-sequence
	if (!document.querySelector("[data-scroll-sequence]")) return;

	const scrollSequence = document.querySelector("[data-scroll-sequence]");

	// switch case to handle different kinds of scroll sequence values
	switch (scrollSequence.dataset.scrollSequence) {
		case "sticky":
			initStickyScrollSequence(scrollSequence, scroll);
			break;
	}
};
